import React from "react"
import "./App.css"
import LabEditor from "scenes/LabEditor"
import {ApolloProvider} from "@apollo/client"
import client from "api/apollo"
import {createTheme} from "theme"
import {ThemeProvider, StyledEngineProvider} from "@mui/material/styles"
import useSettings from "hooks/useSettings"
import {BrowserRouter, Route, Routes} from "react-router-dom"

function App() {
  const {settings} = useSettings()

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Routes>
              <Route path="/:sessionId/:taskId" element={<LabEditor/>}/>
            </Routes>
          </BrowserRouter>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
