type CCIEventType = "lifecycle" | "request"

interface CCIEvent {
  type: CCIEventType | [CCIEventType, "success" | "error"]
  message?: string
  payload?: string | object
}

export default function postIframeMessage(input: CCIEvent) {
  window.parent.postMessage(JSON.stringify(input), "*")
}
