import {gql} from "@apollo/client"

export const SAVE_PROGRESS = gql`
  mutation SaveProgress($source: String!, $taskId: ID!) {
    saveProgress(source: $source, taskId: $taskId) {
      _id
    }
  }
`

export const CREATE_COMPILER_SUBMISSION_FOR_TASK = gql`
  mutation CreateCompilerSubmissionForTask(
    $source: String!
    $compilerId: Int!
    $compilerVersionId: Int
    $taskId: ID!
  ) {
    createCompilerSubmissionForTask(
      source: $source
      compilerId: $compilerId
      taskId: $taskId
      compilerVersionId: $compilerVersionId
    ) {
      id
    }
  }
`
export const CREATE_PROBLEM_SUBMISSION_FOR_TASK = gql`
  mutation CreateProblemSubmissionForTask(
    $compilerId: Int!
    $compilerVersionId: Int
    $source: String!
    $taskId: String!
  ) {
    createProblemSubmissionForTask(
      compilerId: $compilerId
      compilerVersionId: $compilerVersionId
      source: $source
      taskId: $taskId
    ) {
      id
      
    }
  }
`
