import {useLazyQuery, useMutation} from "@apollo/client"
import {Paper, Typography} from "@mui/material"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import {
  SAVE_PROGRESS,
  CREATE_COMPILER_SUBMISSION_FOR_TASK,
  CREATE_PROBLEM_SUBMISSION_FOR_TASK
} from "api/apollo/mutations"
import Dialog from "components/Dialog"
import makeStyles from "@mui/styles/makeStyles"
import {
  RETRIEVE_COMPILER_SUBMISSION,
  RETRIEVE_PROBLEM_SUBMISSION,
  GET_TASK
} from "api/apollo/queries"
import {CodeEditor} from "@knowledge-pillars-education-inc/kp-fe-lib"
import {statusCodeToColor} from "consts/colors"
import {
  CreateCompilerSubmissionForTaskMutation,
  CreateCompilerSubmissionForTaskMutationVariables,
  CreateProblemSubmissionForTaskMutation,
  CreateProblemSubmissionForTaskMutationVariables,
  GetTaskQuery,
  QueryGetTaskArgs,
  QueryRetrieveCompilerSubmissionArgs,
  RetrieveCompilerSubmissionQuery,
  RetrieveProblemSubmissionQuery,
  RetrieveProblemSubmissionQueryVariables,
  SaveProgressMutation,
  SaveProgressMutationVariables, TaskObjective
} from "generated/graphql"
import React, {useEffect, useMemo, useState} from "react"
import {useParams} from "react-router"
import postIframeMessage from "services/postIframeMessage"

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    width: "100%",
    height: "100vh",
    top: 0,
    left: 0,
    overflow: "auto",
    fontFamily: "\"Segoe UI\",Helvetica,Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\", sans-serif"
  },
  layout: {
    position: "relative",
    width: "100%",
    height: "100%",
    minHeight: "580px",
    background: "#000"
  }
}))

export default function LabEditor() {
  const params = useParams()

  const s = useStyles()

  const [initialTaskLoaded, setInitialTaskLoaded] = useState(false)
  const [codeInput, setCodeInput] = useState(undefined)
  const [showContinueDialog, setShowContinueDialog] = useState(false)
  const [savingStatus, setSavingStatus] = useState<string | null>(null)
  const [taskResult, setTaskResult] = useState<(any & {
    userInput: string
    objectives: Array<TaskObjective & {
      passed: boolean
    }>
  }) | null>(null)
  const [submissionLoading, setSubmissionLoading] = useState(false)

  const queryContext = params.sessionId && {
    headers: {
      "sessionId": params.sessionId
    }
  }

  const [queryTask, {
    loading: taskQueryLoading,
    error: taskError,
    data: taskData
  }] = useLazyQuery<GetTaskQuery, QueryGetTaskArgs>(GET_TASK, {
    fetchPolicy: "network-only",
    context: queryContext,
    onCompleted: data => {
      setInitialTaskLoaded(true)
      postIframeMessage({
        type: "request",
        message: "getTask__response",
        payload: data
      })
    },
    onError: error => {
      setInitialTaskLoaded(true)
      postIframeMessage({
        type: ["request", "error"],
        message: "getTask__response",
        payload: error
      })
    }
  })
  const editorData = taskData?.getTask || null

  const taskLoading = useMemo(() => {
    return !initialTaskLoaded && taskQueryLoading
  }, [initialTaskLoaded, taskQueryLoading])

  const [createCompilerSubmission, {
    loading: compilerSubmissionLoading
  }] = useMutation<
    CreateCompilerSubmissionForTaskMutation,
    CreateCompilerSubmissionForTaskMutationVariables
  >(CREATE_COMPILER_SUBMISSION_FOR_TASK, {
    context: queryContext,
    onCompleted: data => {
      postIframeMessage({
        type: "request",
        message: "createCompilerSubmissionForTask__response",
        payload: data
      })
    },
    onError: error => {
      postIframeMessage({
        type: ["request", "error"],
        message: "createCompilerSubmissionForTask__response",
        payload: error
      })
    }
  })

  const [queryRetrieveCompilerSubmission, retrieveCompilerSubmission] = useLazyQuery<
    RetrieveCompilerSubmissionQuery,
    QueryRetrieveCompilerSubmissionArgs
  >(RETRIEVE_COMPILER_SUBMISSION, {
    context: queryContext,
    onCompleted: data => {
      postIframeMessage({
        type: "request",
        message: "retrieveCompilerSubmission__response",
        payload: data
      })

      if (data.retrieveCompilerSubmission.executing === false) {
        postIframeMessage({
          type: "lifecycle",
          message: "COMPILER_SUBMISSION_FINISHED"
        })
      }
    },
    onError: err => {
      postIframeMessage({
        type: ["request", "error"],
        message: "retrieveCompilerSubmission__response",
        payload: err
      })

      postIframeMessage({
        type: "lifecycle",
        message: "COMPILER_SUBMISSION_FINISHED"
      })
    }
  })

  const [createProblemSubmission] = useMutation<
    CreateProblemSubmissionForTaskMutation,
    CreateProblemSubmissionForTaskMutationVariables
  >(CREATE_PROBLEM_SUBMISSION_FOR_TASK, {
    context: queryContext,
    onCompleted: data => {
      postIframeMessage({
        type: "request",
        message: "createProblemSubmissionForTask__response",
        payload: data
      })
    }
  })

  const [queryRetrieveProblemSubmission, retrieveProblemSubmission] = useLazyQuery<
    RetrieveProblemSubmissionQuery,
    RetrieveProblemSubmissionQueryVariables
  >(RETRIEVE_PROBLEM_SUBMISSION, {
    context: queryContext,
    onError: error => {
      postIframeMessage({
        type: ["request", "error"],
        message: "retrieveProblemSubmission__request",
        payload: error
      })

      postIframeMessage({
        type: "lifecycle",
        message: "TASK_SUBMISSION_FAILED"
      })
    }
  })

  if (retrieveCompilerSubmission.data?.retrieveCompilerSubmission.executing === false) {
    retrieveCompilerSubmission.stopPolling()
  }

  if (retrieveProblemSubmission.data?.retrieveProblemSubmission.executing === false) {
    retrieveProblemSubmission.stopPolling()
  }

  const [saveProgress] = useMutation<SaveProgressMutation, SaveProgressMutationVariables>(SAVE_PROGRESS, {
    context: queryContext
  })

  useEffect(() => {
    postIframeMessage({
      type: "lifecycle",
      message: "CCI_MOUNT"
    })

    postIframeMessage({
      type: "request",
      message: "getTask__request",
      payload: {
        taskId: params.taskId || ""
      }
    })

    queryTask({
      variables: {
        taskId: params.taskId || ""
      }
    }).then(({data}) => {
      if (!!data?.getTask?.currentState?.editorInput) {
        setShowContinueDialog(true)
      } else {
        setCodeInput(data?.getTask.practicalDetails.template || undefined)
      }
    })

    return () => {
      postIframeMessage({
        type: "lifecycle",
        message: "CCI_UNMOUNT"
      })
    }
  }, [])

  const handleRestoreProgress = () => {
    const currentState = taskData?.getTask?.currentState

    if (!!currentState) {
      setCodeInput(currentState.editorInput)
    }

    setShowContinueDialog(false)
  }

  const handleRunCode = async (input: string) => {
    if (editorData) {
      const {compilerId} = editorData.practicalDetails
      const compilerVersionId = taskData.getTask?.practicalDetails?.compilerVersionId

      postIframeMessage({
        type: "lifecycle",
        message: "COMPILER_SUBMISSION_BEGIN"
      })

      postIframeMessage({
        type: "request",
        message: "createCompilerSubmissionForTask__request",
        payload: {
          source: input,
          compilerId,
          compilerVersionId,
          taskId: params.taskId
        }
      })

      const res = await createCompilerSubmission({
        variables: {
          source: input,
          compilerId,
          compilerVersionId,
          taskId: params.taskId
        }
      })

      if (!!res.data) {
        postIframeMessage({
          type: "request",
          message: "retrieveCompilerSubmission__request",
          payload: {
            submissionId: res.data.createCompilerSubmissionForTask.id
          }
        })

        queryRetrieveCompilerSubmission({
          variables: {
            submissionId: res.data.createCompilerSubmissionForTask.id
          },
          pollInterval: 500
        })
      }
    }
  }

  const handleSave = (input: string) => {
    if (editorData) {
      setSavingStatus("saving...")

      postIframeMessage({
        type: "lifecycle",
        message: "SAVE_PROGRESS_BEGIN"
      })

      postIframeMessage({
        type: "request",
        message: "saveProgress__request",
        payload: {
          taskId: params.taskId,
          source: input
        }
      })

      saveProgress({
        variables: {
          taskId: params.taskId,
          source: input
        }
      }).then(res => {
        postIframeMessage({
          type: "request",
          message: "saveProgress__response",
          payload: res
        })

        postIframeMessage({
          type: "lifecycle",
          message: "SAVE_PROGRESS_FINISHED"
        })

        setSavingStatus("saved")
      }).catch(err => {
        postIframeMessage({
          type: ["request", "error"],
          message: "saveProgress__request",
          payload: err
        })

        postIframeMessage({
          type: "lifecycle",
          message: "SAVE_PROGRESS_FAILED"
        })

        setSavingStatus("saving error")
      }).finally(() => {
        setTimeout(() => {
          setSavingStatus(null)
        }, 3000)
      })
    }
  }

  const handleSubmitTask = (input: string) => {
    if (editorData) {
      const {compilerId, compilerVersionId} = editorData.practicalDetails

      setSubmissionLoading(true)

      postIframeMessage({
        type: "lifecycle",
        message: "TASK_SUBMISSION_BEGIN",
        payload: {
          source: input
        }
      })

      postIframeMessage({
        type: "request",
        message: "createProblemSubmissionForTask__request",
        payload: {
          source: input,
          compilerId,
          compilerVersionId,
          taskId: params.taskId
        }
      })

      createProblemSubmission({
        variables: {
          source: input,
          compilerId,
          compilerVersionId,
          taskId: params.taskId
        }
      }).then(async (res) => {
        async function call() {
          let result: any

          result = await queryRetrieveProblemSubmission({
            variables: {
              submissionId: res.data.createProblemSubmissionForTask.id
            },
            fetchPolicy: "network-only",
            pollInterval: 2000
          })

          if (result?.data?.retrieveProblemSubmission?.executing !== false) {
            call()
          } else {
            if (result.data?.retrieveProblemSubmission) {
              postIframeMessage({
                type: "request",
                message: "retrieveProblemSubmission__response",
                payload: result.data
              })

              if (result.data?.retrieveProblemSubmission?.result?.status?.code === 15) {
                postIframeMessage({
                  type: "lifecycle",
                  message: "CORRECT_TASK_SUBMISSION"
                })
              } else {
                postIframeMessage({
                  type: "lifecycle",
                  message: "WRONG_TASK_SUBMISSION"
                })
              }

              queryTask({
                variables: {
                  taskId: params.taskId || ""
                }
              }).then(({data}) => {
                setTaskResult({
                  ...result.data.retrieveProblemSubmission.result,
                  userInput: input,
                  objectives: (data?.getTask.objectives || []).map(i => {
                    return {
                      _id: i._id,
                      description: i.description,
                      passed: data.getTask.currentState?.objectivesResult[i._id] || false
                    }
                  })
                })
              }).finally(() => {
                postIframeMessage({
                  type: "lifecycle",
                  message: "TASK_SUBMISSION_FINISHED"
                })
              })
            } else {
              setTaskResult(null)
            }

            setSubmissionLoading(false)
          }
        }

        postIframeMessage({
          type: "request",
          message: "retrieveProblemSubmission__request",
          payload: {
            submissionId: res.data.createProblemSubmissionForTask.id
          }
        })

        call()
      }).catch(err => {
        postIframeMessage({
          type: ["request", "error"],
          message: "createProblemSubmissionForTask__request",
          payload: err
        })

        postIframeMessage({
          type: "lifecycle",
          message: "TASK_SUBMISSION_FAILED"
        })

        postIframeMessage({
          type: "lifecycle",
          message: "TASK_SUBMISSION_FINISHED"
        })

        setSubmissionLoading(false)
      })
    }
  }

  const alert = useMemo(() => {
    if (savingStatus) {
      return {
        name: savingStatus
      }
    } else {
      return retrieveCompilerSubmission.data?.retrieveCompilerSubmission?.result?.status
    }
  }, [savingStatus, retrieveCompilerSubmission.data])

  const compiledResult = useMemo(() => {
    return retrieveCompilerSubmission.data?.retrieveCompilerSubmission?.result?.streams
  }, [retrieveCompilerSubmission.data])

  const resultHasMultipleObjectives = useMemo(() => {
    if (!taskResult?.objectives?.length) {
      return false
    } else {
      return taskResult.objectives.length > 1
    }
  }, [taskResult])

  const resultHasNotPassedObjectives = useMemo(() => {
    if (!taskResult?.objectives?.length) {
      return false
    } else {
      return taskResult.objectives.filter(i => !i.passed).length !== 0
    }
  }, [taskResult])

  return (
    <Box className={s.root}>
      <Box className={s.layout}>
        {taskLoading ? (
          <Box
            display="flex"
            height="100%"
            alignItems="center"
            justifyContent="center">
            <CircularProgress color="error"/>
          </Box>
        ) : taskError || !editorData ? (
          <Box
            display="flex"
            height="100%"
            alignItems="center"
            justifyContent="center">
            <h3 style={{color: "#fff"}}>Task not found</h3>
          </Box>
        ) : (
          <CodeEditor
            key="codeEditor"
            header={{
              title: editorData.displayName,
              noFullScreen: true
            }}
            code={{
              lang: editorData.practicalDetails.language,
              input: codeInput,
              onRun: handleRunCode,
              onSave: handleSave,
              submissionLoading: compilerSubmissionLoading || retrieveCompilerSubmission.loading,
              alert
            }}
            compiled={
              compiledResult?.cmpinfo?.content ||
              compiledResult?.error?.content ||
              compiledResult?.output?.content
            }
            onSubmitTask={handleSubmitTask}
            submitLoading={submissionLoading}
            layoutChangeable
          />
        )}
      </Box>
      <Dialog
        open={showContinueDialog}
        actions={[{
          label: "No",
          color: "warning",
          onClick: () => setShowContinueDialog(false)
        }, {
          label: "Yes",
          color: "success",
          onClick: handleRestoreProgress
        }]}>
        <Box width="20rem">
          <Typography variant="h6">
            Continue where you left off?
          </Typography>
        </Box>
      </Dialog>
      <Dialog
        open={!!taskResult}
        title="Result:"
        actions={[{
          label: "Retry",
          color: "error",
          show: taskResult?.status.code != "15" || resultHasNotPassedObjectives,
          onClick: () => setTaskResult(null)
        }]}>
        <Box width="32rem">
          <Typography
            variant="h6"
            mb={2}
            style={{color: statusCodeToColor[taskResult?.status.code]}}>
            {taskResult?.status.name || ""}
          </Typography>
          {resultHasMultipleObjectives && (
            <>
              <Typography variant="h6" mb={1}>
                {`Score: ${taskResult?.score}%`}
              </Typography>
              <Box>
                {taskResult.objectives.map(i => (
                  <Box key={i._id} mb={1} display="flex" alignItems="center" gap={1}>
                    {i.passed ? (
                      <CheckCircleIcon color="success"/>
                    ) : (
                      <HighlightOffIcon color="error"/>
                    )}
                    <Typography variant="body1">
                      {i.description}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </>
          )}
          <Box mt={2}>
            <Paper variant="outlined" sx={{overflow: "auto"}}>
              <Box p={2}>
                <code style={{fontSize: 13, whiteSpace: "pre-wrap"}}>
                  {taskResult?.userInput || ""}
                </code>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}
