import {ButtonTypeMap, Slide} from "@mui/material"
import Button from "@mui/material/Button"
import DialogMui from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import {TransitionProps} from "@mui/material/transitions"
import React from "react"

interface Props {
  open?: boolean
  title?: string
  actions: ({
    show?: boolean
    label?: string
    autoFocus?: boolean
    onClick?: () => void
  } & ButtonTypeMap["props"])[]
}

const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Dialog({
  open,
  title,
  children,
  actions = []
}: React.PropsWithChildren<Props>) {
  const actionsToShow = actions.filter((i) => i.show !== false)

  return (
    <DialogMui
      open={open}
      style={{zIndex: 999999}}
      TransitionComponent={DialogTransition}>
      {title && (
        <DialogTitle>
          {title}
        </DialogTitle>
      )}
      {children && (
        <DialogContent>
          {children}
        </DialogContent>
      )}
      {!!actionsToShow?.length && (
        <DialogActions sx={{p: 2}}>
          {actionsToShow.map((i, num) => {
            const {show, ...buttonProps} = i

            return (
              <Button key={num} {...buttonProps} sx={{px: 2, py: 1}}>
                {i.label}
              </Button>
            )
          })}
        </DialogActions>
      )}
    </DialogMui>
  )
}
