export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE"
}

export const kpPrimaryColors = {
  lightBlue: "#2192C0",
  darkBlue: "#002955",
  kpYellow: "#eabc07",
  oldBlue: "#10355F"
}

export const kpNeutralsColors = {
  lightGray: "#777777",
  darkGray: "#151515",
  contrastGray: "#eeeee"
}

export const kpSecondaryColors = {
  purple: "#ac82ba",
  green: "#93bb45",
  orange: "#db922f"
}

export const statusCodeToColor = {
  0: "#00c2ee",
  1: "#00c2ee",
  2: "#00c2ee",
  3: "#00c2ee",
  4: "#00c2ee",
  5: "#00c2ee",
  6: "#00c2ee",
  7: "#00c2ee",
  8: "#00c2ee",
  11: "#ec7b7b",
  12: "#ec7b7b",
  13: "#ec7b7b",
  14: "#ec7b7b",
  15: "#1ea46c",
  17: "#ec7b7b",
  20: "#ec7b7b"
}
