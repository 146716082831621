import {gql} from "@apollo/client"

export const GET_TASK = gql`
  query GetTask($taskId: ID!) {
    getTask(taskId: $taskId) {
      displayName
      description
      objectives {
        _id
        description
      }
      practicalDetails {
        compilerId
        language
        template
        compilerId
        compilerVersionId
      }
      problem {
        id
        name
        code
      }
      currentState {
        taskId
        problemSubmissionId
        isAnswerCorrect
        editorInput
        isAnswerGiven
        objectivesResult
      }
    }
  }
`

export const RETRIEVE_COMPILER_SUBMISSION = gql`
  query RetrieveCompilerSubmission($submissionId: Float!) {
    retrieveCompilerSubmission(submissionId: $submissionId) {
      executing
      problem {
        name
      }
      result {
        score
        status {
          _statusText
          code
          name
        }
        streams {
          source {
            content
          }
          output {
            content
          }
          error {
            content
          }
          cmpinfo {
            content
          }
        }
      }
    }
  }
`

export const RETRIEVE_PROBLEM_SUBMISSION = gql`
  query RetrieveProblemSubmission($submissionId: Float!) {
    retrieveProblemSubmission(submissionId: $submissionId) {
      id
      date
      executing
      result {
        score
        status {
          _statusText
          code
          name
        }
        streams {
          source {
            content
          }
          output {
            content
          }
          error {
            content
          }
          cmpinfo {
            content
          }
        }
      }
    }
  }
`
